<template>
    <head>
    <link rel="stylesheet" href="path/to/font-awesome/css/font-awesome.min.css">
  </head>
  <Navbar/>
  <div class="my-4"
        data-aos="zoom-in"
        data-aos-duration="2000"
        data-aos-delay="0"
        data-aos-once="true">
  <center><p id="into">Digital Healthcare Solution<br> Provider</p></center>
  <center><div class="hrr"></div></center>
</div>

<div class="second">
<center><div class="row py-2">
  <div class="col-md-4">
    <div class="circ">
      <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="white" class="bi bi-person-fill-add bicon" viewBox="0 0 16 16">
      <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
      <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z"/>
</svg>
    </div>
    <div class="triangle-down"></div>
    <p class="par"
    data-aos="zoom-in"
        data-aos-duration="3000"
        data-aos-delay="0"
        data-aos-once="true">
      Book physical or virtual medical appointment with any hospital or doctor and get instant feedback in less than one minute
    </p>
  </div>
  <div class="col-md-4">
    <!-- <img src="../assets/center.png" id="imageset"/> -->
    <div class="circ">
      <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="white" class="bi bi-pc-display bicon" viewBox="0 0 16 16">
      <path d="M8 1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V1Zm1 13.5a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0Zm2 0a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0ZM9.5 1a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5ZM9 3.5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 0-1h-5a.5.5 0 0 0-.5.5ZM1.5 2A1.5 1.5 0 0 0 0 3.5v7A1.5 1.5 0 0 0 1.5 12H6v2h-.5a.5.5 0 0 0 0 1H7v-4H1.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5H7V2H1.5Z"/>
</svg>
    </div>
    <div class="triangle-down"></div>
    <p class="par"
    data-aos="zoom-in"
        data-aos-duration="3000"
        data-aos-delay="0"
        data-aos-once="true">
      Manage, share  and secure all your medical appointment records and patient physical, social and medical history
    </p>
  </div>
  <div class="col-md-4">
    <!-- <img src="../assets/right.png" id="imageset"/> -->
    <div class="circ">
      <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="white" class="bi bi-pc-display-horizontal bicon" viewBox="0 0 16 16">
      <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5v7A1.5 1.5 0 0 0 1.5 10H6v1H1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5v-1h4.5A1.5 1.5 0 0 0 16 8.5v-7A1.5 1.5 0 0 0 14.5 0h-13Zm0 1h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5ZM12 12.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM1.5 12h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1ZM1 14.25a.25.25 0 0 1 .25-.25h5.5a.25.25 0 1 1 0 .5h-5.5a.25.25 0 0 1-.25-.25Z"/>
      </svg>
    </div>
    <div class="triangle-down"></div>
    <p class="par"
    data-aos="zoom-in"
        data-aos-duration="3000"
        data-aos-delay="0"
        data-aos-once="true">
      Fast, easy and automated appointment billing and debt tracking system for both patients and hospitals.
    </p>
  </div>
</div></center>
</div>

<section id="third"
        data-aos="zoom-in"
        data-aos-duration="2000"
        data-aos-delay="1000"
        data-aos-once="true">
<center>
  <p id="head">OUR DRIVE</p>
  <p class="tex">Customer Centric || Innovation || Excellence</p>
  <p class="contx">MyHospitul Technologies is a Health Technology company that has developed Hospital Management Software that enables hospitals easily access, input, edit, and share medical records from the comfort of a computer. MyHospitul software gives the hospital better control over their hospital administrative processes and saves them time and energy.</p>
  <p class="contx">
    We are on a mission to improve the way hospital processes are carried out through the use of our Healthcare Management Software in Nigerian Hospitals and Africa at large
  </p>
  <div id="separ"></div>

  <div class="row thrrr">
    <div class="col-md-4">
      <img src="../assets/thirdfirst.png"/>
      <p class="contxx">Medical Appointment Scheduling</p>
    </div>
    <div class="col-md-4">
      <img src="../assets/thirdsec.png"/>
      <p class="contxx">Complete Bill Management & Debt Tracking</p>
    </div>
    <div class="col-md-4">
      <img src="../assets/thirdthird.png"/>
      <p class="contxx">Integrated Medical Records Management</p>
    </div>
  </div>
  <div class="row thrrr">
    <div class="col-md-4">
      <img src="../assets/thirdfirst.png"/>
      <p class="contxx">Prescription Management & Drug Monitoring</p>
    </div>
    <div class="col-md-4">
      <img src="../assets/thirdsec.png"/>
      <p class="contxx">Complete Human Resource Management Module (Shift Tracking, Payroll etc.)</p>
    </div>
    <div class="col-md-4">
      <img src="../assets/thirdthird.png"/>
      <p class="contxx">Laboratory and Medical Investigations Management</p>
    </div>
  </div>
</center>
</section>
<center><div id="separ"></div></center>
<Reviews />
<section id="fourth">
  <center>
  <p id="head" class="py-4">WE ARE VALUE DRIVEN</p></center>
  <div style="margin: auto auto; width: 90%;"
        data-aos="zoom-in"
        data-aos-duration="2000"
        data-aos-delay="1000"
        data-aos-once="true">
    <div class="row">
      <div class="col-md-6">
        <div class="row py-3">
          <div class="col-md-3">
            <div class="circle"> 28 </div>
          </div>
          <div class="col-md-9">
            <p class="topicc">28 Hospital Clients</p>
            <p class="contxx">Since inception in September 2021, we have partnered with 28 hospitals in <span style="font-weight: bold;">Port Harcourt, Delta and Akwa Ibom State Nigeria</span></p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row py-3">
          <div class="col-md-3">
            <div class="circle"> 100% </div>
        </div>
        <div class="col-md-9">
          <p class="topicc">100% Customer retention</p>
          <p class="contxx">So far since 2021, we have had a 100% customer retention</p>
        </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="row py-3">
          <div class="col-md-3">
            <div class="circle"> 50,000</div>
        </div>
        <div class="col-md-9">
          <p class="topicc">Over 50,000 Medical Records</p>
          <p class="contxx">MyHospitul manages over 50,000 medical records with a daily average of 200 new records amongst all our select hospitals</p>
        </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row py-3">
          <div class="col-md-3">
            <div class="circle"> 3 </div>
        </div>
        <div class="col-md-9">
          <p class="topicc">Across 3 States</p>
          <p class="contxx">We have a Customer Service Portfolio cutting across 3 States in Nigeria</p>
        </div>
        </div>
      </div>
    </div>
  </div>
  <cumtomers />
<BaSe />
</section>

<div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasExampleLabel"><img src="../assets/myhospitulogo.png" alt="myhospitul_logo" width="40" height="40" class="d-inline-block align-text-top">
      <p class="fw-900 my-2 title mx-2"><span style="color:red">MY</span> HOSPITUL</p></h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div class="body">
      <ul data-aos="zoom-out"
        data-aos-duration="2000"
        data-aos-delay="0"
        data-aos-once="true">
        <li data-bs-dismiss="offcanvas" aria-label="Close"><router-link class="linkz" to="/">Home</router-link></li>
        <li data-bs-dismiss="offcanvas" aria-label="Close"><router-link class="linkz" to="/demo">Demo</router-link></li>
        <li data-bs-dismiss="offcanvas" aria-label="Close"><router-link class="linkz" to="/modulesview">What we do</router-link></li>
        <li data-bs-dismiss="offcanvas" aria-label="Close"><router-link class="linkz" to="/FAQ">FAQ</router-link></li>
        <li data-bs-dismiss="offcanvas" aria-label="Close"><router-link class="linkz" to="/ContactUs">Contact Us</router-link></li>
      </ul>
    </div>
  </div>
</div>
</template>

<script>
import Navbar from "../components/Navbar.vue"
import Reviews from "../components/Review.vue"
import cumtomers from "../components/cutomers.vue"
import BaSe from "../components/Base.vue"
export default {
 name: "HomeView",
 components: {
  Navbar,
  BaSe,
  cumtomers,
  Reviews
 }
}
</script>

<style>
#into {
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  color:rgb(3, 101, 2);
}
.hrr {
  height: 6px;
  border-radius: 20px;
  width: 10%;
  background-color: rgb(3, 101, 2);
}
.second{
  background-color: rgb(230,240,230);
}
.par{
  padding: 10px;
  color: rgb(3, 101, 2);
  font-size: 18px;
}
#head{
  font-size: 20px;
  font-weight: 600;
  color: rgb(3, 101, 2);
  padding-top: 20px;
}
#third{
  margin: 20px 0px 10px 0px;
}
.tex {
  font-weight: 600;
  font-size: 17px;
  letter-spacing: 2px;
}
.contx{
  width: 65%;
  font-size: 17px;
  letter-spacing: 1px;
  line-height: 38px;
}
#separ{
  margin-top: 60px;
  width: 80%;
  height: 1px;
  border-radius: 20px;
  background-color: rgb(211,211,211);
}
.thrrr{
  width: 85%;
  padding-top: 15px;
}
.contxx {
  padding: 5px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 2px;
}
#fourth{
  background-color: rgb(230,240,230);
}
.topicc{
  padding: 5px;
  color: rgb(3, 101, 2);
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: bold;
}
.offcanvas-title{
  display: flex;
}
.title {
  color: rgb(3, 101, 2);
}
.linkz{
  text-decoration: none;
  color: rgb(130, 132, 133);
  font-size: 18px;
}
li{
  padding: 10px 0px 20px 0px;
}
ul {
  list-style-type: none;
}
.circ{
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: rgb(3, 101, 2);
}
.triangle-down{
  margin-top: 20px;
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
	border-right: 40px solid transparent;
	border-top: 60px solid rgb(3, 101, 2);
}
.bicon{
  transform: translateY(80%);
}
@media only screen and (max-width: 600px) {
 #imageset{
  height: 60vh;
  width: 60%;
 }
}
.circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display:flex;
  justify-content: center;
  align-items: center;
  color:rgb(244, 241, 241);
  font-size: 25px;
  font-weight:bold;
  background: radial-gradient(circle, #034e03, rgb(16, 201, 12));
}
</style>