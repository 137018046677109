<template>
  <div style="font-size: 18px">
    <div
      style="
        background-color: rgb(3, 101, 2);
        padding: 20px;
        margin-bottom: 20px;
      "
    >
      <center>
        <p class="h3" style="color: white">
          MyHospitul Policy/Terms & Condition of use
        </p>
      </center>
    </div>
    <div style="padding: 10px; line-height: 2.5">
      <p style="color: rgb(3, 101, 2); font-weight: 600">
        These Terms and Conditions (hereinafter referred to as "Terms") govern
        the use of the MyHospitul Software Application (hereinafter referred to
        as "the Application"), provided by MyHospitul Technology Limited
        (hereinafter referred to as "the Company"). By accessing or using the
        Application, you agree to be bound by these Terms. If you do not agree
        to these Terms, please do not use the Application.
      </p>
      <div class="box">
        <h4>1. Use of the Application</h4>
        <p>
          1.1. The Application is intended for personal use, and people below the age of 15 may need some guidance while using it
        </p>
        <p>
          1.2. You agree not to use the Application for any unlawful or
          prohibited purpose. If found guilty of any unlawful practice, MyHospitul will your access revoke access to your account on data.
        </p>
        <p>
          1.3. You are responsible for maintaining the confidentiality of your
          account and password and for restricting access to your devices.
        </p>
      </div>

      <div class="box">
        <h4>2. Medical Information</h4>
        <p> 2.1. Please note that MyHospitul will be sharing your personal medical information to a Doctor/Hospital/Lab Scientist user when you schedule a procedure or appointment with them
        </p>
        <p>2.2. The Company has embedded security measures to ensure your data is not transferred to any medical center without your permission (which involves appointment scheduling)
        </p>
        <p>2.3. The Company provides a means of sharing Medical Information for external use, Please note that the company does not take account or control the security or use of your documents outside MyHospitul App.
        </p>
      </div>
      <div class="box">
        <h4>
      3. User Content </h4>
        <p> 3.1. You may
      submit content to the Application, such as personal health data or
      feedback. You retain ownership of your content but grant the Company a
      worldwide, non-exclusive, royalty-free license to use, reproduce, modify,
      and distribute your content for the purpose of operating and improving the
      Application.
        </p>
        <p> 3.1. If at any point you are not comfortable with the terms and wish to delete your account or data from our system. Kindly visit <a href="https://myhospitul.com/delete-account">Delete my Account</a>, please note that the process is irreversible!
        </p>
      </div> 
      
      <div class="box">
        <h4>4. Privacy  </h4>
        <p>  4.1. Your use of the Application is also governed
      by our Privacy Policy, which can be found here Link to our privacy policy.
        </p>
      </div> 
      
      <div class="box">
        <h4>5. Termination  </h4>
        <p>   5.1. The Company reserves the right to terminate or suspend
      your access to the Application at any time, with or without notice, for
      any reason or no reason. 
        </p>
        <p> 5.2. Upon termination, you must cease using the
      Application, and any provisions of these Terms that should reasonably
      survive termination will continue to apply.
        </p>
      </div> 

      <div class="box">
        <h4>6. Disclaimer of Warranties  </h4>
        <p> 6.1. The Application is provided "as is" and "as available" without any
      warranties, express or implied. The Company disclaims all warranties,
      including but not limited to the implied warranties of merchantability,
      fitness for a particular purpose, and non-infringement. 
        </p>
      </div> 
      <div class="box">
        <h4>7. Limitation of Liability  </h4>
        <p> 
       7.1. To the fullest extent permitted by applicable law, the
      Company shall not be liable for any indirect, incidental, special,
      consequential, or punitive damages, or any loss of profits or revenues,
      whether incurred directly or indirectly. 
        </p>
      </div>
      
      <div class="box">
        <h4>8. Governing Law and Dispute
      Resolution   </h4>
        <p> 8.1. These Terms are governed by Nigerian Constitution. Any
      disputes related to these Terms or the Application shall be resolved
      through arbitration in accordance with the rules of the Nigerian Health
      Association, and the location of arbitration shall be Port Harcourt,
      Rivers State. The language of the arbitration shall be English.
        </p>
      </div>

      <div class="box">
        <h4>9. Changes</h4>
        <p> 9.1. The Company reserves the right to change these Terms at any
      time. Updated Terms will be posted on the Application, and your continued
      use of the Application after such changes constitutes your acceptance of
      the new Terms.
        </p>
      </div>
      <div class="box">
        <h4>10. Contact Information</h4>
        <p> 10.1. If you have any questions or
      concerns regarding these Terms, please contact us at info@myhospitul.com
      By using the Application, you acknowledge that you have read and
      understood these Terms and agree to be bound by them. 
        </p>
      </div>
      <p style="margin: 20px;"> <b class="primarycolor">MyHospitul Technology Limited</b> <br>Last Updated: 15/10/2023</p>
     
    </div>
  </div>
</template>

<script>
export default {
  name: "PolicyPage",
};
</script>

<style>
.box {
  box-shadow: 5px 5px 5px lightgray;
  border-radius: 5px;
  border: whitesmoke 2px solid;
  margin: 20px;
  padding: 20px;
}
</style>